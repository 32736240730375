export function parsePhysician(physician) {
    if (physician) {
        return {
            '@type': 'Physician',
            'address': physician.address,
            'image': physician.avatar,
            'memberOf': parseMedicalClinic(physician.clinic),
            'medicalSpecialty': parseMedicalSpecialty(physician.specialty),
            'name': physician.name,
            'telephone': physician.phoneNumber,
            'url': physician.url,
        };
    }
}

export function parseMedicalClinic(clinic) {
    if (clinic) {
        return {
            '@type': 'MedicalClinic',
            'address': parsePostalAddress(clinic.address),
            'name': clinic.name,
            'image':
                clinic.image ||
                'https://healthshare.com.au/static/images/empty_avatar.png', // Passing a default because it is required
            'location': parsePostalAddress(clinic.location),
            'telephone': clinic.phoneNumber,
        };
    }
}

export function parseMedicalSpecialty(specialty) {
    if (specialty) {
        return {
            '@type': 'MedicalSpecialty',
            'name': specialty,
        };
    }
}

export function parsePostalAddress(location) {
    if (location) {
        return {
            '@type': 'PostalAddress',
            'addressLocality': location.locality,
            'addressRegion': location.region,
            'postalCode': location.postCode,
            'streetAddress': location.address,
        };
    }
}
