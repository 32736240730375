import {createContext} from 'react';
import {computed, makeObservable, observable} from 'mobx';
import LogRocket from 'logrocket';

import events from 'events.js';

import {PRODUCTION, TRUE_STR} from 'core/constants.js';

import AnalyticsStore from 'core/stores/AnalyticsStore.js';
import GrowthBookStore from 'core/stores/GrowthBookStore.js';
import HealthFundStore from 'core/stores/HealthFundStore.js';
import ParamStore from 'core/stores/ParamStore.js';
import Store from 'core/stores/Store.js';
import DirectoryStore from 'directory/stores/DirectoryStore.js';
import HospitalDirectoryStore from 'directory/stores/HospitalDirectoryStore.js';
import HospitalStore from 'hospitals/stores/HospitalStore.js';
import PracticeGroupStore from 'practices/stores/PracticeGroupStore.js';
import PracticeLocationStore from 'practices/stores/PracticeLocationStore.js';
import PracticeStore from 'practices/stores/PracticeStore.js';
import PromotionsStore from 'promotions/stores/PromotionsStore.js';
import ProfileStore from 'professional/stores/ProfileStore.js';
import SearchStore from 'search/stores/SearchStore.js';

// v2 store
import DirectoryStoreV2 from 'directory/v2/stores/DirectoryStoreV2.js';
import PracticeLocationStoreV2 from 'practices/stores/PracticeLocationStoreV2.js';
import PracticeStoreV2 from 'practices/stores/PracticeStoreV2.js';
import ProfileStoreV2 from 'professional/stores/v2/ProfileStore.js';

export class RootStore extends Store {
    constructor() {
        super();
        // Global stores
        this.analyticsStore = new AnalyticsStore(this);
        this.paramStore = new ParamStore(this);
        this.healthFundStore = new HealthFundStore(this);

        this.growthBookStore = new GrowthBookStore(this);

        // Page-specific stores
        this.promotionsStore =
            location.pathname.match(/\/profile\//) ||
            typeof jest !== 'undefined'
                ? new PromotionsStore(this)
                : null;
        this.profileStore =
            location.pathname.match(/\/profile\//) ||
            typeof jest !== 'undefined'
                ? new ProfileStore(this)
                : null;
        this.directoryStore =
            location.pathname.match(/\/directory\/(?!.*hospitals).*/) ||
            typeof jest !== 'undefined'
                ? new DirectoryStore(this)
                : null;
        this.hospitalDirectoryStore =
            location.pathname.match(/\/directory\/hospitals/) ||
            typeof jest !== 'undefined'
                ? new HospitalDirectoryStore(this)
                : null;
        this.searchStore = new SearchStore(this);
        this.practiceStore =
            location.pathname.match(/\/practices\/|\/hospitals\//gm) ||
            typeof jest !== 'undefined'
                ? new PracticeStore(this)
                : null;
        this.practiceGroupStore =
            location.pathname.match(/\/practices\//) ||
            typeof jest !== 'undefined'
                ? new PracticeGroupStore(this)
                : null;
        this.practiceLocationStore =
            location.pathname.match(/\/practices\//) ||
            typeof jest !== 'undefined'
                ? new PracticeLocationStore(this)
                : null;
        this.hospitalStore =
            location.pathname.match(/\/hospitals\//) ||
            typeof jest !== 'undefined'
                ? new HospitalStore(this)
                : null;

        // ========v2 version===================
        this.directoryStoreV2 =
            location.pathname.match(/\/directory\/(?!.*hospitals).*/) ||
            typeof jest !== 'undefined'
                ? new DirectoryStoreV2(this)
                : null;

        this.practiceStoreV2 =
            location.pathname.match(/\/practices\/|\/hospitals\//gm) ||
            typeof jest !== 'undefined'
                ? new PracticeStoreV2(this)
                : null;

        this.practiceLocationStoreV2 =
            location.pathname.match(/\/practices\//) ||
            typeof jest !== 'undefined'
                ? new PracticeLocationStoreV2(this)
                : null;
        this.profileStoreV2 =
            location.pathname.match(/\/profile\//) ||
            typeof jest !== 'undefined'
                ? new ProfileStoreV2(this)
                : null;
        makeObservable(this, {
            environment: observable,
            logrocketEnabled: observable,

            isLogrocketEnabled: computed,
            isProduction: computed,
        });

        this.setGlobalVariables();
    }

    environment = '';
    logrocketEnabled = '';

    async setGlobalVariables() {
        await events.ready();

        const {environment, logrocketEnabled} = document.body.dataset;
        this.updateStore({environment, logrocketEnabled});
    }

    get isProduction() {
        return this.environment === PRODUCTION;
    }

    get isLogrocketEnabled() {
        return this.logrocketEnabled === TRUE_STR;
    }

    async setupLogrocket() {
        if (!this.isLogrocketEnabled) {
            return;
        }
        const APP_ID = this.isProduction
            ? 'healthsharecomau'
            : 'testhealthsharecomau';
        LogRocket.init(`kc3htu/${APP_ID}`);
        const {practiceUserId, integrationUsageId} = document.body.dataset;
        if (practiceUserId) {
            LogRocket.identify(practiceUserId, {
                integrationUsageId,
            });
        }
    }
}

const store = new RootStore();
if (location.href.startsWith('http://healthshare.test/')) {
    window.RootStore = store;
}
// Global store contexts
export const ParamStoreContext = createContext(store.paramStore);
export const AnalyticsStoreContext = createContext(store.analyticsStore);
export const HealthFundStoreContext = createContext(store.healthFundStore);

// Anything search-related
export const SearchStoreContext = createContext(store.searchStore);

// Directory Pages
export const DirectoryStoreContext = createContext(store.directoryStore);
export const HospitalDirectoryStoreContext = createContext(
    store.hospitalDirectoryStore,
);

// Profile page
export const ProfileStoreContext = createContext(store.profileStore);
export const PromotionsStoreContext = createContext(store.promotionsStore);

// Practice Page
export const PracticeStoreContext = createContext(store.practiceStore);
export const PracticeGroupStoreContext = store.practiceGroupStore;
export const PracticeLocationStoreContext = store.practiceLocationStore;
export const HospitalStoreContext = store.hospitalStore;

// V2 Redesign
// Practice page
export const PracticeStoreContextV2 = createContext(store.practiceStoreV2);
export const PracticeLocationStoreContextV2 = store.practiceLocationStoreV2;

// Directory page
export const DirectoryStoreContextV2 = createContext(store.directoryStoreV2);

// Profile page
export const ProfileStoreContextV2 = store.profileStoreV2;
