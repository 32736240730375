import {TELEHEALTH_ONLY} from 'core/telehealth.js';

// Extracts data from a passed in location object
export function getCopyDataForLocation({
    fax,
    street1,
    street2,
    city,
    postCode,
    state,
    phones,
    name,
}) {
    const bits = [];
    if (name) {
        bits.push(name);
    }
    if (street1) {
        bits.push(street1);
    }
    if (street2) {
        bits.push(street2);
    }
    bits.push(`${city} ${state} ${postCode}`);
    if (phones?.length) {
        bits.push(`Phone: ${phones[0].number}`);
    }
    if (fax?.number) {
        bits.push(`Fax: ${fax.number}`);
    }
    return bits.join('\r\n');
}

// Extracts data from an available practice and adds a profile detail if provided
export function getCopyData(profileName, location) {
    const {
        address,
        fax,
        locality,
        name,
        offersTelehealth,
        phones,
        postCode,
        referralEmail,
        street1,
        street2,
        streetAddress,
    } = location;
    const bits = [];
    if (name) {
        bits.push(name);
    }
    if (profileName) {
        bits.push(profileName);
    }
    if (offersTelehealth !== TELEHEALTH_ONLY) {
        if (address) {
            bits.push(address);
        } else {
            if (streetAddress) {
                bits.push(streetAddress);
            }
            if (street1) {
                bits.push(street1);
            }
            if (street2) {
                bits.push(street2);
            }
            if (locality) {
                bits.push(locality);
            } else {
                const city = location.city || location.localityName;
                const state = location.state || location.localityState;
                bits.push(`${city} ${state} ${postCode}`);
            }
        }
    }

    if (phones?.length) {
        bits.push(`Phone: ${phones[0].number}`);
    }
    if (fax) {
        bits.push(`Fax: ${fax.number}`);
    }
    if (referralEmail) {
        bits.push(`Referral Email: ${referralEmail}`);
    }
    return bits.join('\r\n');
}
