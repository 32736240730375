import React from 'react';

import autobind from 'common/decorators/autobind.js';
import analytics from 'analytics.js';
import {trackEvent as genericTrackEvent} from 'analytics.js';
import Store from 'core/stores/Store.js';

export default class AnalyticsStore extends Store {
    @autobind
    trackEvent(eventName, data) {
        if (eventName && data) {
            analytics.track(eventName, data);
        } else {
            const missingParam = [];
            if (!eventName) {
                missingParam.push('eventName');
            }
            if (!data) {
                missingParam.push('data');
            }
            this.throwError(
                `Missing required AnalyticsStore tracking parameter: ${missingParam.join(
                    ', ',
                )}`,
            );
        }
    }

    genericTrackEvent(url, data) {
        return genericTrackEvent(url, data);
    }
}
